import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage, Modal } from '../../components';
import { AvatarLarge, InlineTextButton } from '../../components';

// import ImageCarousel from './ImageCarousel/ImageCarousel';
import ActionBarMaybe from './ActionBarMaybe';
import css from './ListingPage.module.css';
import './SectionHero.css';

function takeElementsAndFill(array, number, fillString) {
  const takenElements = _.take(array, number);
  const remainingSlots = Math.max(number - takenElements.length, 0);
  const filledArray = takenElements.concat(
    Array(remainingSlots).fill(fillString)
  );

  return filledArray;
}

const BIO_COLLAPSED_LENGTH = 500;

const truncated = s => {
  return _.truncate(s, {
    length: BIO_COLLAPSED_LENGTH,

    // Allow truncated text end only in specific characters. This will
    // make the truncated text shorter than the length if the original
    // text has to be shortened and the substring ends in a separator.
    //
    // This ensures that the final text doesn't get cut in the middle
    // of a word.
    separator: /\s|,|\.|:|;/,
    omission: '…',
  });
};

const ExpandableBio = props => {
  const [expand, setExpand] = useState(false);
  const { bio } = props;
  const truncatedBio = truncated(bio);

  const handleShowMoreClick = () => {
    setExpand(true); 
  };
  const showMore = (
    <InlineTextButton
      rootClassName={css.showMore}
      onClick={handleShowMoreClick}
    >
      <FormattedMessage id="UserCard.showFullBioLink" />
    </InlineTextButton>
  );
  return (
    <p>
      {expand ? bio : truncatedBio}
      {bio !== truncatedBio && !expand ? showMore : null}
    </p>
  );
};

const SectionHero = props => {
  const {
    title,
    richTitle,
    description,
    listing,
    isOwnListing,
    editParams,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
    pageName,
    listingType,
    listings,
    bioText,
    showAsIngress = false,
    isSeller,
  } = props;
  
  const [bioModalOpen, setBioModalOpen] = useState(false);

  const handleBioModalOpen = () => {
    setBioModalOpen(true);
  }

  const closeBioModal = () => {
    setBioModalOpen(false);
  }

  const hasImages = listing.images && listing.images.length > 0;
  const firstImage = hasImages ? listing.images[0] : null;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k =>
        k.startsWith('scaled')
      )
    : [];

  const listingImages = useMemo(
    () =>
      takeElementsAndFill(
        _.map(_.sampleSize(listings, 7), listingImageItem => {
          return listingImageItem.images[0].attributes.variants['listing-card'].url;
        }),
        7,
        '/static/images/landing/temp/placeholder.png'
      ),
      [listings]
  );

  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel
  const actionBar = listing.id ? (
    <div onClick={e => e.stopPropagation()}>
      <ActionBarMaybe
        className={css.actionBarForHeroLayout}
        isOwnListing={isOwnListing}
        listing={listing}
        editParams={editParams}
        pageName={pageName}
        listingType={listingType}
      />
    </div>
  ) : null;

  // const viewPhotosButton = hasImages ? (
  //   <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
  //     <FormattedMessage
  //       id="ListingPage.viewImagesButton"
  //       values={{ count: listing.images.length }}
  //     />
  //   </button>
  // ) : null;

  const bioTextClass = showAsIngress ? css.ingress : css.text;

  return (
    <div className={css.sectionHero} data-testid="hero">
      {actionBar}
      <section className="artistbanner categoryview inbeta">
        <div className="container">
          <div className="container full">
            <div className="cat-grid text">
              <div className="box">
                <p className="storelabel">
                  Artist Storefront
                  <span className="betaindicator">Beta</span>
                </p>
                <div className="storeIntro">
                  {firstImage && (
                    <div className="lead-icon">
                      <AvatarLarge
                        customImage={firstImage}
                        className={css.providerAvatar}
                        disableProfileLink={true}
                      />
                    </div>
                  )}
                  <h4 className="heading">
                    <FormattedMessage
                      id="StoreListingPage.orderTitle"
                      values={{ title: richTitle }}
                    />
                  </h4>
                </div>
                {description && (
                  <p className={bioTextClass}>
                    {truncated(description)}
                    {description !== truncated(description) ? (
                      <InlineTextButton
                        rootClassName={css.showMore}
                        onClick={handleBioModalOpen}
                      >
                        <FormattedMessage id="UserCard.showFullBioLink" />
                      </InlineTextButton>
                    ) : null}
                  </p>
                )}
                {/*<ExpandableBio bio={description} />*/}
              </div>

              {listings.length ? (
                <div className="list">
                  <div className="row1">
                    <div className="box box1">
                      <span className="image hover-large">
                        <img src={listingImages[0]} className="" />
                      </span>
                    </div>
                    <div className="box box2">
                      <span className="image hover-large">
                        <img src={listingImages[1]} className="" />
                      </span>
                    </div>
                    <div className="box box3">
                      <span className="image hover-large">
                        <img src={listingImages[2]} className="" />
                      </span>
                    </div>
                  </div>
                  <div className="row2">
                    <div className="box box1">
                      <span className="image hover-large">
                        <img src={listingImages[3]} className="" />
                      </span>
                    </div>
                    <div className="box box2">
                      <span className="image hover-large">
                        <img src={listingImages[4]} className="" />
                      </span>
                    </div>
                    <div className="box box3">
                      <span className="image hover-large">
                        <img src={listingImages[5]} className="" />
                      </span>
                    </div>
                    <div className="box box4">
                      <span className="image hover-large">
                        <img src={listingImages[6]} className="" />
                      </span>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="betadetails">
              {isSeller ? (
                <p>
                  Customizable artist storefronts coming soon.
                </p>
              ) : (
                <p>
                  Customizable artist storefronts coming soon.{' '}
                  <a href="/become-a-seller" className="becomeseller">
                    Apply to become a seller
                  </a>{' '}
                  on Handmade.com.
                </p>
              )}
            </div>

            {bioText && (
              <div className={`${bioTextClass} ${css['bio-text']}`}>
                {truncated(bioText)}
                {description !== truncated(bioText) ? (
                  <InlineTextButton
                    rootClassName={css.showMore}
                    onClick={handleBioModalOpen}
                  >
                    <FormattedMessage id="UserCard.showFullBioLink" />
                  </InlineTextButton>
                ) : null}
              </div>
            )}
            {/*<ExpandableBio bio={bioText} />*/}
          </div>
        </div>
      </section>
      <Modal
        id="SectionHero.ExpandableBio"
        isOpen={bioModalOpen}
        onClose={closeBioModal}
        containerClassName={css.container}
        usePortal={false}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.shipToContent}>
          <h5 className="heading">
            <FormattedMessage
              id="StoreListingPage.orderTitle"
              values={{ title: richTitle }}
            />
          </h5>
          {bioText && <div className={bioTextClass}>{bioText}</div>}
          {description && <div className={bioTextClass}>{description}</div>}
        </div>
      </Modal>
      {/* <div className={css.imageWrapperForSectionHero} onClick={handleViewPhotosClick}>
        {actionBar}
        <ResponsiveImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
        />
        {viewPhotosButton}
      </div>
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel
          images={listing.images}
          imageVariants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
        />
      </Modal> */}
    </div>
  );
};

export default SectionHero;
