import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import {
  LISTING_STATE_PENDING_APPROVAL,
  propTypes,
  JH_LISTING_TYPES,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { ensureListing, ensureOwnListing } from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  manageDisableScrolling,
  isScrollingDisabled,
} from '../../ducks/ui.duck';

import {
  H4,
  Page,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  LoadingPage,
  ErrorPage,
  listingImages,
} from './StoreListingPage.shared';
import SectionHero from '../ListingPage/SectionHero';
import SectionTextMaybe from '../ListingPage/SectionTextMaybe';
import SectionDetailsMaybe from '../ListingPage/SectionDetailsMaybe';

import SectionListings from './SectionListings';
import { loadMoreListings } from './StoreListingPage.duck';
import { isSeller } from '../../extensions/common/user/utils';
import css from './StoreListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;
const THREAD_HOLD = 700;

const { UUID } = sdkTypes;

export const ListingPageComponent = props => {
  const dispatch = useDispatch();
  const [imageCarouselOpen, setImageCarouselOpen] = useState(false);

  const { queryListingsInProgress, listingsMeta, listings } = useSelector(
    state => state.StoreListingPage
  );

  const {
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    listingConfig: listingConfigProp,
    config,
    listingId,
  } = props;

  // prop override makes testing a bit easier
  // TODO: improve this when updating test setup
  const listingConfig = listingConfigProp || config.listing;
  const isPendingApprovalVariant =
    rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));
  const listingSlug =
    rawParams.slug || createSlug(currentListing.attributes.title || '');
  const storeSlug =
    currentListing?.author?.attributes?.profile?.publicData?.storeName;
  const params = { storeSlug };

  const {
    description = '',
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  const authorId = currentListing.author?.id?.uuid;
  const { totalPages, page: currentPage, totalItems } = listingsMeta;

  useEffect(() => {
    const handleLoadMoreListings = e => {
      if (
        document.documentElement.offsetHeight -
          (window.innerHeight + document.documentElement.scrollTop) >
          THREAD_HOLD ||
        queryListingsInProgress
      ) {
        return;
      }

      const page = currentPage + 1;
      if (totalPages >= page) {
        dispatch(loadMoreListings({ authorId, page, config }));
      }
    };
    document.addEventListener('scroll', handleLoadMoreListings, {
      passive: true,
    });
    return () => {
      // This cleans up the event handler when the component unmounts.
      document.removeEventListener('scroll', handleLoadMoreListings);
    };
  }, [queryListingsInProgress]);

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id &&
    currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage =
    pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return (
      <NamedRedirect
        name="StoreListingPage"
        params={params}
        search={location.search}
      />
    );
  }

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return (
      <ErrorPage
        topbar={topbar}
        scrollingDisabled={scrollingDisabled}
        intl={intl}
      />
    );
  } else if (!currentListing.id) {
    // Still loading the listing
    return (
      <LoadingPage
        topbar={topbar}
        scrollingDisabled={scrollingDisabled}
        intl={intl}
      />
    );
  }

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const isOwnListing =
    currentListing.author?.id?.uuid === currentUser?.id?.uuid;

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'StoreListingPage.schemaTitle' },
    { title, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;

  const handleViewPhotosClick = e => {
    // Stop event from bubbling up to prevent image click handler
    // trying to open the carousel as well.
    e.stopPropagation();
    setImageCarouselOpen(true);
  };
  
  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
        },
      }}
    >
      <LayoutSingleColumn
        className={css.pageRoot}
        topbar={topbar}
        footer={<FooterContainer />}
      >
        <SectionHero
          title={title}
          richTitle={richTitle}
          description={description}
          listing={currentListing}
          listings={listings}
          isOwnListing={isOwnListing}
          editParams={{
            id: listingId.uuid,
            slug: listingSlug,
            type: listingPathParamType,
            tab: listingTab,
          }}
          imageCarouselOpen={imageCarouselOpen}
          onImageCarouselClose={() => setImageCarouselOpen(false)}
          handleViewPhotosClick={handleViewPhotosClick}
          onManageDisableScrolling={onManageDisableScrolling}
          pageName="EditShopPage"
          listingType={JH_LISTING_TYPES.STORE_LISTING}
          isSeller={isSeller(currentUser)}
        />
        {/* <div className={css.contentWrapperForHeroLayout}>
          <div className={css.mainColumnForHeroLayout}>
            <div className={css.heading}>
              <H4 as="h1" className={css.orderPanelTitle}>
                <FormattedMessage
                  id="StoreListingPage.orderTitle"
                  values={{ title: richTitle }}
                />
              </H4>
            </div>
            <SectionTextMaybe text={description} showAsIngress />
          </div>
        </div> */}
        <SectionListings
          listingNumber={totalItems}
          listings={listings}
          queryListingsInProgress={queryListingsInProgress}
        />
      </LayoutSingleColumn>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  showListingError: null,
  listingConfig: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([
      LISTING_PAGE_DRAFT_VARIANT,
      LISTING_PAGE_PENDING_APPROVAL_VARIANT,
    ]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  listingConfig: object,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const { showListingError, id } = state.StoreListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    showListingError,
    listingId: new UUID(id),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const StoreListingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedListingPage);

export default StoreListingPage;
